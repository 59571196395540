export default {
  pt: { 
    curaviva:{
      name:"A VIDA DE ROSEMARIE | CURAVIVA",
      descr:'O grande desafio do projeto da Roof era criar um estilo de animação diferenciado e a proposta da parceria com a Onfire Powerhouse trouxe a solução técnica: misturar simulação de tecidos com um estilo de animação em CG com cara de stop motion.'
    },
    wick:{
      name:'WICKBOLD',
      descr:'Essa foi nossa primeira parceria com o pessoal da Mascoteria e já foi extremamente divertida. Juntando a habilidade deles em criar mascotes cativantes com a nossa, aqui na Onfire Powerhouse, de dar vida a esses mascotes, criamos uma série de músicas infantis para promover uma melhor alimentação com as crianças. '
    },
    turquesa:{
      name:'TURQUESA - EMBOLADA DO MELHOR FEIJAO',
      descr:'Fazendo parceria novamente com o pessoal na Mascoteria, a Onfire Powerhouse resolveu dar uma nova cara para esse mascote já tão conhecido entre o público. A ideia era manter a identidade já estabelecida ao mesmo tempo que dando mais personalidade e vida para esse porquinho, através da animação..'
    },
    natura:{
      name:'NATURA - NAT GANHA VIDA',
      descr:'Acreditamos que nossos projetos podem ajudar a mudar o mundo também, essa também é a filosofia da Natura. Com a nossa parceira Anymotion, trouxemos à vida a Nat, pessoa virtual da Natura, que, de maneira leve e descontraída, aborda temas relevantes para criarmos um mundo melhor'
    },
    lol:{
      name:'CBLOL - Abertura do Campeonato Brasileiro de League of Legends',
      descr:'LOL é mais que um jogo, é paixão mundial! E a Riot, conhecendo seus fãs como ninguém, contratou a Histeria para fazer o melhor cinematic para a final do campeonato brasileiro de LOL, o grandioso CBLOL. E através do diretor de CG, Paulo Sampaio, a OnFire PowerHouse juntou forças com o time da Histeria para trazer a vida os lendários heróis do jogo no meio da cidade de São Paulo'
    },
    love2:"AMOR POR HISTÓRIA",
    we_are:"Nós somos",
    what_do_we_do: "O QUE FAZEMOS?",
    wedo1: 'ESTABELECEMOS UM RELACIONAMENTO E O CLIENTE TERCEIRIZA A PRODUÇÃO DA ANIMAÇÃO CONOSCO',
    wedo2: 'FEITO ISSO, NÓS DIRIGIMOS, SUPERVISIONAMOS, COORDENAMOS E RECRUTAMOS TODO MUNDO PARA O PROCESSO DE ANIMAÇÃO DO PROJETO. ESSA SEQUÊNCIA DE FEEDBACK ACONTECE JUNTO DE REUNIÕES CONSTANTES COM O CLIENTE PARA GARANTIR QUE O RESULTADO ESTÁ DE ACORDO COM SEU DESEJO.',
    wedo3: 'ALÉM DISSO, TEMOS UM POOL COM MAIS DE 20 ANIMADORES DE ALTA PERFORMANCE COM PORTFÓLIOS VASTOS CONTENDO PROJETOS MUNDO AFORA.',
    wedo4: 'POR FIM, ENTREGAMOS A ANIMAÇÃO JÁ PREPARADA PARA OS PRÓXIMOS ESTÁGIOS DE PRODUÇÃO COM O CLIENTE.',
    we_also: "TAMBÉM CRIAMOS",
    we_also2: "NOSSAS PRÓPRIAS",
    profile1: "Bruno Monteiro tem mais de 10 anos de experiência em animação. Venceu o prêmio de cristal do festival Annecy em 2014 como diretor de animação do longa metragem “Rio 2096”. Trabalha como diretor e diretor de animação no estúdio Zombie, e é diretor e fundador da OnFire Escola de Animação Online. Como diretor de animação no estúdio Zombie, ganhou prata na premiação Clio de 2017 com “Dream” da WCFF e prata no Cannes Lions de 2018 com “The Fall”. Trabalhou como animador em projetos como “Asterix e os Vikings”, “The secret of Kells”, “Rock Dog”, “Rio 2096”, “Friends forever”, “League of Gods” e muitos outros.",
    profile2: "Rodrigo Dutra trabalhou no campo musical até 2010, possuindo graduação em marketing público. De 2010 a 2014, esteve envolvido em diversos projetos de CGI como escultura digital, design de movimentos e animação. Em 2015, decidiu se especializar em animação de personagens, e desde então tem trabalhado em diversos projetos como longa e curta metragens, programas de televisão, jogos digitais e mais. Ele ainda foi diretor de animação do projeto “Dolmen the Game”, assim como supervisor de animação de “Tainá e os Guardiões da Amazônia”, a série de TV da Nickelodeon. Em 2019, foi diretor de animação no estúdio Zombie trabalhando no comercial animado “Brilhe do seu jeito –  Bradesco”",
    profile3: "Francisco iniciou sua carreira como animador com 16 anos. A direção de filmes começou quando ele entrou na faculdade e venceu o “Melhor Filme” para seu curta de 6 minutos, “Opaco”, no Festival Estudantil de Filmes em 2013. Entre 2015 e 2017, seus próximos dois filmes “Two sided animation” e “Katabasis” venceram prêmios no México, Brasil, Estados Unidos e Paquistão. Durante todo esse período, ele continuou a trabalhar em diversos projetos para as mais variadas empresas, participando de anúncios e projetos publicitários. Atualmente, ele trabalha remotamente em dois novos curtas e também coordena e anima projetos de empresas no Brasil, Reino Unido, EUA e Austrália.",
    name: "NOME COMPLETO",
    create:"VAMOS CRIAR JUNTOS!",
    tell: "CONTA PRA GENTE",
    submit: "Enviar",
    company: "Empresa",
    menu:{
      home: 'Home',
      love: 'Amor por Histórias',
      create: 'Cria sua história',
      team: 'Equipe',
      contact: 'Contato'
    },
    currency: {
      style: 'currency', currency: 'USD'
    }  
  }
};
