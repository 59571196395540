
import { createI18n } from 'vue-i18n'
import eng,{currency} from '@/i18n/en';
import pt from '@/i18n/pt';

const numberFormats = {
    ...currency    
}

const messages = {
  ...eng, ...pt 
}
export const i18n = createI18n({
    locale: 'pt', // set locale
    fallbackLocale: 'pt',
    localeDir: "i18n", // set fallback locale
    messages,
    numberFormats
})