
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {},
})
export default class App extends Vue {
  public showMenu = false;
  public fullPath = false;
  public mounted(){
    console.log(this.$route.path)
    if (this.$route.path == '/'){
      this.fullPath = true;
    }
  }

  public sendMenu(link:string){
    this.showMenu = false;
    window.location.href= `/powerhouse/${link}`
  }

}
