
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {},
})
export default class Home extends Vue {
  public sendToLink(link:string){
    this.$router.push(link)
  }
}
