export default {
  en: { 
    curaviva:{
      name:"ROSEMARIE'S LIFE | CURAVIVA",
      descr:'The challenge with this project from Roof was to create a different style of animation and the partnership with Onfire Powerhouse brought the solution: mix cloth simulation with a stop-motion like feeling for animation. '
    },
    wick:{
      name:'WICKBOLD',
      descr:'This was our first partnership with the team at Mascoteria and it was fun from the get go. Putting together their ability in creating very captivating mascots with ours, here at Onfire Powerhouse, of giving life to those mascots, we created a series of animated music videos to promote a better nutrition among kids.'
    },
    turquesa:{
      name:'TURQUESA - THE BEST BEANS DANCE',
      descr:'Making another partnership with the team at Mascoteria, Onfire Powerhouse decided to give a new face to a mascot already well established with the public. The idea was to maintain the original identity and at the same time giving more personality and life to this little pig, through animation.'
    },
    natura:{
      name:'NATURA - NAT BROUGHT TO LIFE',
      descr:'We believe that our projects can, not only help the client, but also help change the world, little by little. Natura shares this philoshopy with us and together with Anymotion, we were able to bring to life Nat, Naturas virtual persona, which in a light and relaxed way, talks about relevant issues for us to create a better world for everyone.'
    },
    lol:{
      name:'CBLOL - Brazilian League of Legends Championship Opening Cinematic',
      descr:'League of Legends is more than a game, is a world wide passion! Riot who knows their fans like anyone, hired Histeria to make the best cinematic movie for the brazilian championship final of LOL, the great CBLOL. And through the CG director, Paulo Sampaio, us from Onfire Powerhouse joined forces with an amazing team to bring the legendary heroes from the game straight to the middle of big Sao Paulo'
    },
    love2:"LOVE FOR HISTORY",
    we_are:"We are",
    what_do_we_do: "What do we do?",
    wedo1: 'WE ESTABLISH A RELATIONSHIP AND THE CLIENT OUTSOURCES THE ANIMATION PRODUCTION TO US.',
    wedo2: 'WE THEN, DIRECT, SUPERVISE, COORDINATE AND RECRUIT EVERYONE FOR THE ANIMATION PROCESS OF THE PROJECT. THIS WHOLE FEEDBACK LOOP HAPPENS TOGETHER WITH CONSTANT MEETINGS WITH THE CLIENT TO GUARANTEE THE RESULT IS ACCORDING TO THEIR WISH.',
    wedo3: 'WE HAVE A POOL OF MORE THEN 20 HIGH QUALITY ANIMATORS WISH THE MOST VAST PORTIFOLIOS IN PROJECTS AROUND THE GLOBE',
    wedo4: 'WE THEN, DELIVER ALL THE ANIMATION ALREADY FIXED AND READY FOR THE NEXT STAGES OF PRODUCTION WITH THE CLIENT.',
    we_also: "We also",
    we_also2: "CREATE OUR OWN",
    profile1: "Bruno Monteiro has more than 20 years of animation experience. Won Annecy’s Cristal Award 2014 best feature film as Animation Director for “Rio 2096”. Works as Director and  Animation Director at Zombie Studios and is founder/director of OnFire online Animation School. As Animation director at Zombie Studios, won Silver at Clio Awards 2017 with WCFF’s “Dream” and Silver Cannes Lions 2018 with “The Fall” Worked as animator for projects like “Asterix and the Vikings”, “Brendan and the Secret of Kells”, “Rock Dog”, “Rio 2096”, “Friends Forever”, “League of Gods” and many more.",
    profile2: "Rodrigo Dutra worked in the music field until 2010, having graduated with a degree in public marketing. From 2010 to 2014, he was involved in many CGI projects such as digital sculpting, motion design and animation. In 2015, he decided to specialize in character animation, and since then, he worked on many projects such as feature and short films,  TV shows, digital games and more. In addition, he was the animation director of the project “Dolmen the Game”, as well as the animation supervisor on the Nickelodeon TV series “Tainá and The Guardians of the Amazon”. In 2019, he was the animation director at Zombie Studio working on the commercial movie “Shine in Your Own Way – Bradesco”.",
    profile3: "Francisco started his career as an animator when he was 16. Directing movies only started when he got into college and won “Best Movie” for his 6 min short “Opaco” on the Student Film Festival of 2013. Between 2015 and 2017, his two next movies “Two sided Animation” and “Katabasis” won awards in Mexico, Brazil, United States and Pakistan. During all that period, he continued to work in many different projects for the most different companies and participating in advertising and publicity awards with them. Today, he works remotely on two new shorts and also coordinates and animates in projects for companies in Brazil, UK, US and Australia.",
    name: "FULL NAME",
    create:"LET'S CREATE TOGETHER!",
    tell: "TELL US WHAT YOU HAVE TO SAY",
    submit: "Submit",
    company: "COMPANY",
    menu:{
      home: 'Home',
      love: 'Love for Stories',
      create: 'Create our Own',
      team: 'Team',
      contact: 'Contact'
    },
    currency: {
      style: 'currency', currency: 'USD'
    }  
  }
};

export const currency = { 
  'en': {
    currency: {
      style: 'currency', currency: 'USD'
    }
  }
};