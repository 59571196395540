import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    meta: {
      hideNavbar: true,
    },
    component: () => import('../views/Splash.vue')
  },
  {
    path: '/powerhouse',
    name: 'Home2',
    component: Home
  },
  {
    path: '/powerhouse/space-scape',
    component: () => import('../views/Space.vue')
  },
  {
    path: '/powerhouse/cblol',
    component: () => import('../views/LOL.vue')
  },
  {
    path: '/powerhouse/naim',
    component: () => import('../views/Naim.vue')
  },
  {
    path: '/powerhouse/curaviva',
    component: () => import('../views/Curaviva.vue')
  },
  {
    path: '/powerhouse/capibaribe',
    component: () => import('../views/Capibaribe.vue')
  },
  {
    path: '/powerhouse/wickbold',
    component: () => import('../views/Wickbold.vue')
  },
  {
    path: '/powerhouse/turquesa',
    component: () => import('../views/Turquesa.vue')
  },
  {
    path: '/powerhouse/natura',
    component: () => import('../views/Natura.vue')
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
